import React from "react";
import useIsMobile from "../../hooks/useIsMobile";
import TextButton from "../Buttons/TextButton";

const Notifier = ({ text, buttonText, buttonTargetRoute }) => {
  const isMobile = useIsMobile();

  return (
    <div className="notifier">
      <p className="notifier__text">{text}</p>
      <TextButton
        rightArrow={!isMobile}
        text={buttonText}
        targetRoute={buttonTargetRoute}
      />
    </div>
  );
};

export default Notifier;
