import React, { useEffect, useState } from "react";

const TextCarousel = ({ data = [], style, smallText }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const carouselInterval = setInterval(() => {
      setActiveIndex(prev => (prev + 1 >= data?.length ? 0 : prev + 1));
    }, 4000);

    return () => {
      clearInterval(carouselInterval);
    };
  }, [data?.length]);

  return (
    <div className="text_carousel" style={style}>
      <div
        className="text_carousel__container"
        style={{
          transform: `translateY(${activeIndex * -50}px)`,
        }}
      >
        {data?.map((name, index) => (
          <p
            key={`destekci-${index}`}
            className={`${smallText && "small_text"}`}
          >
            {name}
          </p>
        ))}
      </div>
    </div>
  );
};

export default TextCarousel;
