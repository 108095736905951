import React from "react";
import CtaButton from "../Buttons/CtaButton";
import Tilt from "react-tilt";
import TextCarousel from "../TextCarousel";
import { Fade } from "react-reveal";
import { GatsbyImage } from "gatsby-plugin-image";
import useIsMobile from "../../hooks/useIsMobile";
import TextButton from "../Buttons/TextButton";

const TextRowWithAsset = ({
  heading,
  text,
  secondParagraph,
  ctaText,
  ctaTargetRoute,
  asset,
  reverse,
  style,
  textCarouselData,
  relatedNewsSlug,
}) => {
  console.log("related news slug: ", relatedNewsSlug);
  const isMobile = useIsMobile();

  return (
    <div
      className={`text_row_with_asset ${reverse ? "reverse" : ""}`}
      style={style}
    >
      <Fade>
        <div
          className={`text_container ${
            secondParagraph ? "multiple_paragraph" : ""
          }`}
        >
          <h1>{heading}</h1>
          <p>{text}</p>
          {secondParagraph && (
            <p className="second_paragraph">{secondParagraph}</p>
          )}
          {relatedNewsSlug && (
            <TextButton
              text="Ürün Detayları"
              targetRoute={`/haberler/${relatedNewsSlug}`}
              rightArrow={true}
              arrowAlwaysVisible
              style={{
                width: isMobile ? "50%" : undefined,
                paddingLeft: 0,
                justifyContent: "flex-start",
              }}
            />
          )}
          {textCarouselData && (
            <TextCarousel data={textCarouselData} smallText />
          )}
          {ctaText && (
            <CtaButton
              text={ctaText}
              secondary
              style={{ margin: 0 }}
              targetRoute={ctaTargetRoute}
            />
          )}
        </div>
      </Fade>
      <div className="asset_container">
        <Tilt
          options={{
            max: 10,
            scale: 1,
          }}
        >
          <div>
            <GatsbyImage image={asset} alt="Yazı ile alakalı bir görsel" />
          </div>
        </Tilt>
      </div>
    </div>
  );
};

export default TextRowWithAsset;
