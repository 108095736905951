import React, { useContext } from "react";
import TextRowWithAsset from "../../TextRowWithAsset";
import { HomepageDataContext } from "../../../context";

const Introduction = () => {
  const data = useContext(HomepageDataContext);

  return (
    <section id="about">
      <TextRowWithAsset
        heading={data?.yazili_resimli_satir_1.baslik}
        text={data?.yazili_resimli_satir_1.aciklama}
        ctaText={data?.yazili_resimli_satir_1.buton.baslik}
        ctaTargetRoute={data?.yazili_resimli_satir_1.buton.rota}
        asset={
          data?.yazili_resimli_satir_1?.resim?.childImageSharp.gatsbyImageData
        }
      />

      <TextRowWithAsset
        heading={data?.yazili_resimli_satir_2.baslik}
        text={data?.yazili_resimli_satir_2.aciklama}
        ctaText={data?.yazili_resimli_satir_2.buton.baslik}
        ctaTargetRoute={data?.yazili_resimli_satir_2.buton.rota}
        asset={
          data?.yazili_resimli_satir_2?.resim?.childImageSharp.gatsbyImageData
        }
        reverse
      />
    </section>
  );
};

export default Introduction;
