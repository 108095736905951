import React, { useContext } from "react";
import { FaTools, FaUserFriends, FaSun } from "react-icons/fa";
import { Fade } from "react-reveal";
import { HomepageDataContext } from "../../../context";

const ICONS = [
  {
    icon: color => <FaTools size={24} color={color} />,
    color: "#1591D3",
  },
  {
    icon: color => <FaUserFriends size={24} color={color} />,
    color: "#1815D1",
  },
  {
    icon: color => <FaSun size={24} color={color} />,
    color: "#15D195",
  },
];

const OfferCard = ({ title = "", text = "", icon, color, index }) => {
  return (
    <Fade delay={200 + index * 200}>
      <div className="offers_card">
        <div className="heading">
          {icon(color)}
          <h1 style={{ color }}>{title}</h1>
        </div>

        <p>{text}</p>

        <span className="bottom_line" style={{ backgroundColor: color }}></span>
      </div>
    </Fade>
  );
};

const Offers = () => {
  const data = useContext(HomepageDataContext);

  return (
    <section id="offers">
      <div className="content_container">
        <OfferCard
          title={data?.Hizmet_1__baslik}
          text={data?.Hizmet_1__aciklama}
          index={0}
          {...ICONS[0]}
        />
        <OfferCard
          title={data?.Hizmet_2__baslik}
          text={data?.Hizmet_2__aciklama}
          index={1}
          {...ICONS[1]}
        />
        <OfferCard
          title={data?.Hizmet_3__baslik}
          text={data?.Hizmet_3__aciklama}
          index={2}
          {...ICONS[2]}
        />
      </div>
    </section>
  );
};

export default Offers;
