import React, { useContext, useMemo } from "react";
import CtaButton from "../Buttons/CtaButton";
import { GatsbyImage } from "gatsby-plugin-image";
import { EventRegistrationContext } from "../../context";
import useIsMobile from "../../hooks/useIsMobile";
import TextButton from "../Buttons/TextButton";

const formatTime = time => {
  const parsed = time.split(":");

  return `${parsed[0]}:${parsed[1]}`;
};

const isDatePassed = dateString => {
  const targetDate = new Date(dateString);
  const today = new Date();

  return targetDate < today;
};

const EventCard = ({
  slug,
  featuredImage,
  title,
  description,
  unformattedDate,
  date,
  time,
  openForm,
  relatedNewsSlug,
}) => {
  const eventData = useContext(EventRegistrationContext);
  const isMobile = useIsMobile();
  const isFinished = useMemo(() => isDatePassed(unformattedDate), [
    unformattedDate,
  ]);

  const onClick = () => {
    eventData?.selectEvent(title);
    openForm();
  };

  return (
    <div className="event_card">
      <GatsbyImage
        image={featuredImage}
        className="event_card__featured_image"
        alt="Etkinlikle alakalı bir görsel"
      />
      <div className="event_card__content">
        <h1>{title}</h1>
        <div className="date_container">
          <p className="date">{date}</p>
          <p className="time">{formatTime(time)}</p>
        </div>
        <p>{description}</p>
        <div className="button_container">
          <CtaButton
            text={isFinished ? "Etkinlik Bitti" : "Bize Katıl"}
            secondary
            danger={isFinished}
            noClick={isFinished}
            onClick={onClick}
            style={
              isMobile
                ? {
                    marginLeft: 0,
                    // width: "30%",
                  }
                : {
                    margin: 0,
                  }
            }
          />
          {relatedNewsSlug && (
            <TextButton
              text="İlgili Haber"
              targetRoute={`/haberler/${relatedNewsSlug}`}
              secondary
              rightArrow={!isMobile}
              arrowAlwaysVisible={!isMobile}
              style={
                isMobile
                  ? {
                      width: "45%",
                    }
                  : undefined
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(EventCard);
