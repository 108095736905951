import React from "react";
import {
  Events,
  Farewell,
  Hero,
  Introduction,
  News,
  Offers,
} from "../../components/sections/home";

const Home = () => {
  return (
    <>
      <Hero />
      <Offers />
      <Introduction />
      <News />
      <Events />
      <Farewell />
    </>
  );
};

export default Home;
