import React from "react";
import TextButton from "../Buttons/TextButton";
import { GatsbyImage } from "gatsby-plugin-image";
import useIsMobile from "../../hooks/useIsMobile";

const NewsCard = props => {
  const { slug, date, title, content, featuredImage } = props;

  const isMobile = useIsMobile();

  return (
    <div className="news_card">
      <GatsbyImage className="image" image={featuredImage} alt={title} />
      <div className="news_card__content">
        <h1>{title}</h1>
        <p className="news_card__content__date">{date}</p>
        <p className="news_card__content__paragraph">{content}</p>
        <TextButton
          text="Devamını Oku"
          style={
            isMobile
              ? { alignSelf: "flex-start", justifyContent: "flex-start" }
              : { alignSelf: "flex-end" }
          }
          targetRoute={`/haberler/${slug}`}
          routePayload={props}
          rightArrow
        />
      </div>
    </div>
  );
};

export default NewsCard;
