import React, { useContext, useState, useCallback } from "react";
import SectionHeader from "../../Headers/SectionHeader";
import EventRegistrationForm from "../../EventRegistrationForm";
import Notifier from "../../Notifier";
import EventCard from "../../EventCard";
import { EventsDataContext, HomepageDataContext } from "../../../context";

const Events = () => {
  const data = useContext(HomepageDataContext);
  const eventsData = useContext(EventsDataContext);

  const [isFormOpen, setIsFormOpen] = useState(false);

  const openForm = useCallback(() => {
    setIsFormOpen(true);
  }, []);

  const closeForm = useCallback(() => {
    setIsFormOpen(false);
  }, []);

  return (
    <section id="homepage_events">
      <EventRegistrationForm isOpen={isFormOpen} closeForm={closeForm} />
      <SectionHeader text="Etkinliklerimiz" />
      <Notifier
        text={data?.etkinlikler_davet_yazisi}
        buttonText="Takvime Git"
        buttonTargetRoute="/etkinlikler"
      />
      {eventsData?.edges.length === 0 ? (
        <p className="handwriting empty_notifier">Çok yakında sizlerle...</p>
      ) : (
        <div className="content_container">
          {eventsData?.edges.slice(0, 4).map(({ node }) => (
            <EventCard
              key={node.slug}
              slug={node.slug}
              featuredImage={
                node?.etkinlik_resmi?.childImageSharp.gatsbyImageData
              }
              relatedNewsSlug={node?.iliskili_haber?.slug}
              title={node.etkinlik_basligi}
              description={node.etkinlik_aciklamasi}
              date={node.etkinlik_tarihi}
              unformattedDate={node.unformattedDate}
              time={node.etkinlik_saati}
              openForm={openForm}
            />
          ))}
        </div>
      )}
    </section>
  );
};

export default Events;
