import React, { useContext, useState } from "react";
import { EventRegistrationContext } from "../../context";
import Modal from "../Modal";
import { sendEmail } from "../../utils/sendEmail";
import CtaButton from "../Buttons/CtaButton";
import TextButton from "../Buttons/TextButton";
import useIsMobile from "../../hooks/useIsMobile";
import ReactModal from "react-modal";

ReactModal.setAppElement("#___gatsby");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    zIndex: 9,
  },
};

const EventRegistrationForm = ({ isOpen, closeForm }) => {
  const isMobile = useIsMobile();
  const eventData = useContext(EventRegistrationContext);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [additionalInformation, setAdditionalInformation] = useState("");

  const [error, setError] = useState("");
  const [errorList, setErrorList] = useState(null);

  const [successMessage, setSuccessMessage] = useState("");

  const reset = () => {
    setName("");
    setEmail("");
    setAdditionalInformation("");
    setError("");
    setErrorList(null);
    setSuccessMessage("");
  };

  const validateForm = () => {
    let errors = {
      name: false,
      email: false,
    };

    let errorFlag = false;

    if (name.length === 0) {
      errors.name = true;
      errorFlag = true;
    }

    if (email.length === 0) {
      errors.email = true;
      errorFlag = true;
    }

    if (errorFlag) {
      setErrorList(errors);
      setError("Lütfen zorunlu alanları doldurun.");
      return false;
    } else {
      setErrorList(null);
      setError("");
      return true;
    }
  };

  const submitHandler = async () => {
    try {
      let validationResult = validateForm();
      if (!validationResult) return;

      await sendEmail({
        name,
        email,
        additionalInformation,
        eventName: eventData?.selectedEvent,
      });

      setSuccessMessage("Başvurunuz alınmıştır.");
      setTimeout(() => {
        reset();
        closeForm();
      }, 1500);
    } catch {
      setError("Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.");
    }
  };

  const cancelHandler = () => {
    reset();
    closeForm();
  };

  return (
    <ReactModal isOpen={isOpen} style={customStyles}>
      <div className="registration_form_container">
        {!isMobile ? <h1>Etkinlik Katılım Formu</h1> : undefined}
        <form>
          <label htmlFor="nameInput">İsim: </label>
          <input
            id="nameInput"
            className={`${errorList?.name && "error"}`}
            maxLength={25}
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            required
          />

          <label htmlFor="emailInput">Email: </label>
          <input
            id="emailInput"
            className={`${errorList?.email && "error"}`}
            maxLength={30}
            type="text"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />

          <label htmlFor="additionalInformationInput">Ek Notlarınız: </label>
          <textarea
            id="additionalInformationInput"
            maxLength={200}
            type="text"
            value={additionalInformation}
            onChange={e => setAdditionalInformation(e.target.value)}
          />
        </form>

        {error ? (
          <p className="error_notifier">{error}</p>
        ) : (
          <p className="success_notifier">{successMessage}</p>
        )}

        <CtaButton onClick={submitHandler} text="Formu Yolla" />
        <TextButton text="Vazgeç" danger onClick={cancelHandler} />
      </div>
    </ReactModal>
  );
};

export default EventRegistrationForm;
