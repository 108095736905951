import React, { useContext } from "react";
import SectionHeader from "../../Headers/SectionHeader";
import Notifier from "../../Notifier";
import NewsCard from "../../NewsCard";
import { HomepageDataContext, NewsDataContext } from "../../../context";

const News = () => {
  const data = useContext(HomepageDataContext);
  const news = useContext(NewsDataContext);

  return (
    <section id="homepage_news">
      <SectionHeader text="Haberlerimiz" light />
      <Notifier
        text={data?.haberler_davet_yazisi}
        buttonText="Haberlere Git"
        buttonTargetRoute="/haberler"
      />
      {news?.edges.length === 0 ? (
        <p className="handwriting empty_notifier">Çok yakında sizlerle...</p>
      ) : (
        <div className="news__container">
          {news?.edges.slice(0, 3).map(({ node }) => {
            return (
              <NewsCard
                key={node.slug}
                slug={node.slug}
                title={node.haber_basligi}
                content={node.haber_icerigi}
                date={node.haber_yazilma_tarihi}
                featuredImage={node?.haber_resmi?.childImageSharp.gatsbyImageData}
              />
            );
          })}
        </div>
      )}
    </section>
  );
};

export default News;
