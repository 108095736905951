import React, { useContext } from "react";
import { HomepageDataContext } from "../../../context";

const Farewell = () => {
  const data = useContext(HomepageDataContext);

  return (
    <section id="farewell">
      <h1 className="handwriting">{data?.veda_mesaji}</h1>
    </section>
  );
};

export default Farewell;
