import React, { useContext } from "react";
import { Zoom } from "react-reveal";
import { StaticImage } from "gatsby-plugin-image";
import { HomepageDataContext } from "../../../context";

const Hero = () => {
  const data = useContext(HomepageDataContext);

  return (
    <section id="homepage_hero">
      <main>
        <Zoom cascade>
          <h1>Atölye Ankara</h1>
        </Zoom>
        {/* <h2>FabLab</h2> */}
        <p>{data?.Giris__Kisa_Aciklama}</p>

        <div className="logo_container">
          <StaticImage
            className="logo"
            src="../../../images/logos/1-meb-logo.png"
            width={180}
            alt="Milli Eğitim Bakanlığı logosu"
            placeholder="blurred"
             
          />
          <StaticImage
            className="logo"
            src="../../../images/logos/2-ankaraka.png"
            width={180}
            alt="Ankara Kalkınma Ajansı logosu"
            placeholder="blurred"
          />
          <StaticImage
            className="logo"
            src="../../../images/logos/4-ostim-logo.png"
            width={180}
            alt="Ostim logosu"
            placeholder="blurred"
          />
          <StaticImage
            className="logo"
            src="../../../images/logos/5-sanayi-bakanlık-logo.png"
            width={180}
            alt="Sanayi Bakanlığı logosu"
            placeholder="blurred"
          />
        </div>
      </main>
      <svg
        id="bg-svg"
        viewBox="0 0 1440 300"
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        className="transition duration-300 ease-in-out delay-150"
      >
        <defs>
          <linearGradient id="gradient">
            <stop offset="5%" stopColor="#002bdc88"></stop>
            <stop offset="95%" stopColor="#32ded488"></stop>
          </linearGradient>
        </defs>
        <path
          d="M 0 600 C 0 600 0 233 0 133 C 103.1641 114.1897 206.3282 95.3795 280 88 C 353.6718 80.6205 397.8513 84.6718 477 99 C 556.1487 113.3282 670.2667 137.9333 755 148 C 839.7333 158.0667 895.0821 153.5949 968 138 C 1040.9179 122.4051 1131.4051 95.6872 1213 93 C 1294.5949 90.3128 1367.2974 111.6564 1440 133 C 1440 600 1440 600 1440 600 Z"
          stroke="none"
          strokeWidth="0"
          fill="url(#gradient)"
          className="transition-all duration-300 ease-in-out delay-150"
        ></path>
        <defs>
          <linearGradient id="gradient">
            <stop offset="5%" stopColor="#002bdcff"></stop>
            <stop offset="95%" stopColor="#32ded4ff"></stop>
          </linearGradient>
        </defs>
        <path
          d="M 0,600 C 0,600 0,266 0,266 C 59.23846153846151,257.67692307692306 118.47692307692301,249.35384615384615 212,247 C 305.523076923077,244.64615384615385 433.3307692307693,248.2615384615385 516,249 C 598.6692307692307,249.7384615384615 636.2,247.59999999999997 709,248 C 781.8,248.40000000000003 889.8692307692309,251.33846153846156 980,260 C 1070.1307692307691,268.66153846153844 1142.323076923077,283.04615384615386 1216,285 C 1289.676923076923,286.95384615384614 1364.8384615384616,276.4769230769231 1440,266 C 1440,266 1440,600 1440,600 Z"
          stroke="none"
          strokeWidth="0"
          fill="url(#gradient)"
          className="transition-all duration-300 ease-in-out delay-150"
        ></path>
      </svg>
    </section>
  );
};

export default Hero;
